body{
    &.newdesignform{
        background: url(https://res.cloudinary.com/spiralyze/image/upload/f_auto/enfusion/2002/background_1922.webp) no-repeat 50%/cover;
        header.fixed{
            display: none;
        }
        footer{
            display: none;
        }
        main{
            > header{
                background-image: none!important;
                align-items: center;
                display: flex;
                justify-content: center;
                min-height: 100vh;
                padding: 54px 32px;
            }
        }
        .cstm-pardot-form{
            display: none;
            &.showform{
                display: block;
                backdrop-filter: none;
                background: none;
            }
            .shadow{
                background: linear-gradient(180deg,#fff 65%,hsla(0,0%,100%,.9));
                border-radius: 16px;
                box-shadow: 0 24px 64px -4px rgba(0,0,0,.6);
            }
        }
    }
}
.dual-cta-inner {
    display: inline-flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 24px;
    margin-top: 24px;
    .learn-more{
        &.btn{
                background: #fff;
                border: 2px solid #7d5fff;
                color: #7d5fff;
                font-family: EuclidCircularA;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: .2px;
                line-height: 24px;
                padding: .4rem 1.175rem;
                text-align: center;
                &:hover{
                    border-color: #4a25aa;
                    color: #4a25aa;
                }
        }
    }
}
.cstm-pardot-form{
    display: none;
    .close-icon{
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1;
        display: none;
    }
    &.showform{
        display: block;
        backdrop-filter: blur(4px);
        background: linear-gradient(180deg,rgba(38,16,93,.56),rgba(38,16,93,.56));
        display: block;
        min-height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 9999;
        .close-icon{
            display: block;
        }
    .cstm-form-inner{
        display: flex;
        height: 100vh;
        justify-content: center;
        overflow-y: scroll;
        padding: 8px;
        width: 100%;
        .cstm-form-flex{
            margin: auto;
            max-width: 584px;
            min-height: 400px;
            position: relative;
            width: 100%;
        }
        }
        .CUSTOM-FORM{
            display: block;
            margin: auto;
            >.shadow{
                background: #fff;
                border-radius: 16px;
                box-shadow: 0 24px 64px -4px rgba(42,30,76,.23);
                padding: 48px 16px 22px;
            }
        }
    }
}