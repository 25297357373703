@import '../public/icons/icons';
/*! purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;

// Import Swiper styles
@import 'swiper.scss';
// @import 'swiper/swiper.scss';
@import 'swiper/swiper-bundle.min.css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import "swiper/css/effect-fade";
// @import 'swiper/components/pagination/pagination.scss';
// @import 'swiper/components/navigation/navigation.scss';
// @import 'swiper/components/effect-fade/effect-fade.scss';

/*! purgecss end ignore */

@import 'fonts';

@import 'typography';
@import 'buttons';
@import 'pattern';
@import 'animate';
@import 'global';
@import 'header';
@import 'testimonials';
@import 'two-col-partners';
@import 'client-carousel';
@import 'podcast-carousel';
@import 'contact-form-hero';
@import 'investment-strategies';
@import 'custom';